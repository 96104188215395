import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import { SCROLLFIRE, OBSERVER } from './plugins';
import fadeTransition from './transitions/default-transition';
import {
  backendKeyBind, calendar, fastLinks, ogContent, photoCredits, redAlert, changeSelect, initZoom, setContentMinHeight,
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import {
  resetDropzone, formsPackage, inputsAndTextareaLabel, clearInput, tailSelect, fastResult, showAllResults
} from './functions/form';
import overlayShare, {
  overlayCalendar,
  overlayDropdown, overlayFastLinks, overlayMenu, overlayPopup, overlaySearch,
} from './functions/overlays';
import Overlay from './vendor/overlay';
import {
  formCallForApplications,
  formContact, formExample, formJobs, formReport, formSuccess,
} from './functions/validation';
import masksPackage from './functions/masks';
import { hundredVH, removeHoverOnMobile, sizeTitle } from './functions/helper';
import { swiperFastLinks, swiperHomeBanner, swiperHomeEvents } from './functions/sliders';
import { clickToScrollToBlock } from './functions/scrollToBlock';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers,
} from './functions/map/map';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    overlayShare();
    overlaySearch();
    overlayDropdown();
    overlayPopup();
    overlayMenu();
    backendKeyBind();
    removeHoverOnMobile();
    inputsAndTextareaLabel();
    clearInput();
    clickToScrollToBlock({ selector: '[data-js="return"]' });
    sizeTitle({
      selectors: '.page-title__title',
      caract01: 40,
      caract02: 60,
      caract03: 80,
    });
    initZoom();
    fastResult('#mainSearchForm');
    fastResult('#mobileSearchForm');
    showAllResults();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [fadeTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          rubrics();
          formsPackage();
          masksPackage();
          dynamicPackage();
          formExample();
          photoCredits();
          setContentMinHeight();
          initFormBuilder();
          recaptchaFormsCallback();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          hundredVH();
          sizeTitle({
            selectors: '.home-banner__title',
            caract01: 40,
            caract02: 60,
            caract03: 80,
          });
          swiperHomeBanner();
          swiperFastLinks();
          swiperHomeEvents();
          fastLinks();
          overlayFastLinks();
          redAlert();
          photoCredits();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventsListView',
        beforeEnter() {
          tailSelect();
          calendar();
          overlayCalendar();
          changeSelect('#form-events-select');
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventDetailView',
        beforeEnter() {
          dynamicPackage();
          photoCredits();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          tailSelect();
          changeSelect('#form-news-select');
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage();
          photoCredits();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobsListView',
        beforeEnter() {
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]' });
          formJobs();
          masksPackage();
          tailSelect();
          formsPackage();
          changeSelect('#form-jobs-select');
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobDetailView',
        beforeEnter() {
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]' });
          formJobs();
          masksPackage();
          formsPackage();
          dynamicPackage();
          photoCredits();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'sectionsView',
        beforeEnter() {
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          formContact();
          masksPackage();
          dynamicPackage();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]' });
          clickToScrollToBlock({ selector: '[data-js="scroll-to-directory"]', scrollTo: '[data-js="directory"]' });
          rubrics();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          tailSelect();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'reportFormView',
        beforeEnter() {
          tailSelect();
          formsPackage();
          masksPackage();
          dynamicPackage();
          formReport();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'callForApplicationsFormView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          dynamicPackage();
          formCallForApplications();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'formView',
        beforeEnter() {
          tailSelect();
          formsPackage();
          masksPackage();
          dynamicPackage();
          formExample();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'interactiveMapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          goBackMap();
          subMenu();
          drawers();
          googleMap();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          destroyMap();
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
