import $ from 'jquery';
import Accordions from '../classes/accordions';
import { OBSERVER } from '../plugins';
import { isMobile, getCookie, setCookie } from './helper';

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.calendar').dataset.list;
  const currentDate = document.querySelector('.calendar').dataset.date;

  $.fn.datepicker.dates.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre',
      'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0,
  };

  const datesEnabled = eventsDatesList.split(',');

  $('.calendar')
    .datepicker({
      language: 'fr',
      maxViewMode: 0,
      format: 'yyyy-mm-dd',
      todayHighlight: true,
      beforeShowDay: (date) => {
        // Rendre seulement les dates de la liste d'événements disponibles
        let returnBoolean;
        const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        if (datesEnabled.indexOf(allDates) !== -1) {
          returnBoolean = true;
        } else {
          returnBoolean = false;
        }
        return returnBoolean;
      },
    })
    .on('changeDate', (e) => {
      // Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
      const theTimestamp = Date.parse(e.date) / 1000;
      $.request('onChangeDate', {
        data: { dateFilter: theTimestamp },
        // update: {'evenementlist::eventslist':'#eventsWrapper'},
        // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
        complete: (data) => {
          data.then((data2) => {
            // eslint-disable-next-line no-undef
            Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
          });
        },
      });
      // Fermer l'overlay quand on clique
      $('#overlayCalendar .wrapper a.close span.x').trigger('click');
    });

  $('.calendar').datepicker('update', currentDate);
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

export function fastLinks() {
  const fastLinksAccordion = new Accordions({
    containerClass: 'js-accordions-fast-links',
    accordionClass: 'js-accordion-fast-links',
    toggleButtonClass: 'js-accordion-fast-links-toggle',
    contentClass: 'js-accordion-fast-links-content',
    openedClass: 'js-accordion-fast-links-opened',
  });

  fastLinksAccordion.init();
}

export function redAlert() {
  const alert = document.querySelector('[data-js="alert"]');
  const alertMobile = document.querySelector('[data-js="alert-mobile"]');

  if (typeof (alertMobile) === 'undefined' || alertMobile === null) {
    document.documentElement.style.setProperty('--mobile-alert-height', '0px');
  }

  const closeAlert = () => {
    alert.classList.add('hide-alert');
  };

  const closeAlertMobile = () => {
    document.documentElement.style.setProperty('--mobile-alert-height', '0px');
  };

  OBSERVER.add({
    name: 'alert',
    events: 'click',
    function: closeAlert,
    targets: '[data-js="close-alert"]',
  });

  OBSERVER.add({
    name: 'alert',
    events: 'click',
    function: closeAlertMobile,
    targets: '[data-js="close-alert-mobile"]',
  });

  OBSERVER.on('alert');
}

export function photoCredits() {
  const hoverItems = document.querySelectorAll('.photo-credits__hover-item');

  function touch(e) {
    const isCreditsVisible = e.currentTarget.nextElementSibling.classList.contains('visible-credits');
    if (isCreditsVisible) {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits');
    } else {
      e.currentTarget.nextElementSibling.classList.add('visible-credits');
    }
  }

  if (!isMobile()) {
    const hoverIn = (e) => {
      e.currentTarget.nextElementSibling.classList.add('visible-credits');
    };
    const hoverOut = (e) => {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits');
    };

    OBSERVER.add({
      name: 'hover',
      events: 'mouseenter',
      targets: hoverItems,
      function: hoverIn,
    });
    OBSERVER.add({
      name: 'hover',
      events: 'mouseleave',
      targets: hoverItems,
      function: hoverOut,
    });
    OBSERVER.on('hover');
  } else if (isMobile()) {
    OBSERVER.add({
      name: 'touch',
      events: 'click',
      targets: hoverItems,
      function: touch,
    });
    OBSERVER.on('touch');
  }
}

export function changeSelect(select) {
  $('.dropdown-option').on('click', () => {
    $(select).trigger('change');
  });
}

// Initialise le zoom sur la typo
export function initZoom() {
  const cookie = getCookie('zoom');
  let i;
  let bindSetZoom;
  const html = document.getElementsByTagName('html')[0];
  const accessibility = document.querySelectorAll('.zoom');
  const accessibilityLength = document.querySelectorAll('.zoom').length;
  const headerForm = document.querySelector('.header__search-form');
  const headerMobileButtons = document.querySelector('.header__mobile-buttons');

  // Initialisation par défaut de la font-size et de son animation
  if (cookie === '') {
    setCookie('zoom', '62.5', 7);
    html.style.fontSize = '62.5%';
  } else {
    html.style.fontSize = `${getCookie('zoom')}%`;

    if (getCookie('zoom') === '68') {
      document.documentElement.style.setProperty('--header-form-max-width', '204px');

      if (headerForm.classList.contains('zoomx2')) {
        headerForm.classList.remove('zoomx2');
        headerMobileButtons.style.display = 'none';
      }
    } else if (getCookie('zoom') === '74') {
      document.documentElement.style.setProperty('--header-form-max-width', '264px');
      headerForm.classList.add('zoomx2');
      headerMobileButtons.style.display = 'flex';
    } else {
      document.documentElement.style.setProperty('--header-form-max-width', '264px');

      if (headerForm.classList.contains('zoomx2')) {
        headerForm.classList.remove('zoomx2');
        headerMobileButtons.style.display = 'none';
      }
    }
  }
  setTimeout(() => { html.style.transition = 'font-size 300ms'; }, 300);

  // Événement de clique pour ajouter la bonne font-size
  function setZoom(e) {
    setCookie('zoom', e.target.dataset.zoom, 7);
    for (i = 0; i < accessibilityLength; i += 1) {
      accessibility[i].classList.remove('active');
    }
    e.target.classList.add('active');
    html.style.fontSize = `${e.target.dataset.zoom}%`;

    if (e.target.dataset.zoom === '68') {
      document.documentElement.style.setProperty('--header-form-max-width', '204px');

      if (headerForm.classList.contains('zoomx2')) {
        headerForm.classList.remove('zoomx2');
        headerMobileButtons.style.display = 'none';
      }
    } else if (e.target.dataset.zoom === '74') {
      document.documentElement.style.setProperty('--header-form-max-width', '264px');
      headerForm.classList.add('zoomx2');
      headerMobileButtons.style.display = 'flex';
    } else {
      document.documentElement.style.setProperty('--header-form-max-width', '264px');

      if (headerForm.classList.contains('zoomx2')) {
        headerForm.classList.remove('zoomx2');
        headerMobileButtons.style.display = 'none';
      }
    }
  }

  // Création des événements de clique
  for (i = 0; i < accessibilityLength; i += 1) {
    if (`${accessibility[i].dataset.zoom}%` === html.style.fontSize) {
      accessibility[i].classList.add('active');
    }
    bindSetZoom = (e) => setZoom(e);
    accessibility[i].addEventListener('click', bindSetZoom, false);
  }
}

export function setContentMinHeight() {
  const aside = document.querySelector('.aside__sticky-item');
  if (!aside) return;

  const asideHeight = aside.offsetHeight;

  document.documentElement.style.setProperty('--aside-height', `${asideHeight}px`);
}
