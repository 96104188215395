import Swiper from 'swiper/bundle';
import { SCROLLFIRE } from '../plugins';

// Swiper de la bannière
export function swiperHomeBanner() {
  // Si il y a plus qu'une slide
  if (document.querySelectorAll('[data-swiper="home-banner-slide"]').length > 1) {
    // Création du slider
    window['home-banner'] = new Swiper('[data-swiper="home-banner"]', {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '[data-swiper="home-banner-pagination"]',
        type: 'bullets',
        clickable: true,
      },
    });
    window['home-banner'].on('slideChange', () => {
      const photoCredits = document.querySelector('.visible-credits');
      if (photoCredits) {
        photoCredits.classList.remove('visible-credits');
      }
    });
  }

  SCROLLFIRE.update();
}

// Swiper des liens rapide
export function swiperFastLinks() {
  if (document.querySelectorAll('[data-swiper="fast-links-slide"]').length > 1) {
    // Création du slider
    window['fast-links'] = new Swiper('[data-swiper="fast-links"]', {
      loop: true,
      speed: 1000,
      slidesPerView: 'auto',
      loopedSlides: 3,
      spaceBetween: 20,
    });
  }
}

// Swiper des événements
export function swiperHomeEvents() {
  if (document.querySelectorAll('[data-swiper="home-events-slide"]').length > 0) {
    // Création du slider
    window['home-events'] = new Swiper('[data-swiper="home-events"]', {
      loop: false,
      speed: 1000,
      autoplay: {
        delay: 700000,
      },
      slidesPerView: 1,
      loopedSlides: 3,
      spaceBetween: 20,
      breakpoints: {
        600: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1000: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
      navigation: {
        prevEl: '[data-swiper="home-events-prev"]',
        nextEl: '[data-swiper="home-events-next"]',
      },
      scrollbar: {
        el: '[data-swiper="home-events-pagination"]',
        draggable: false,
        snapOnRelease: false,
      },
    });
  }
}
