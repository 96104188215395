import $ from 'jquery';
import 'eonasdan-bootstrap-datetimepicker';
import { OBSERVER } from '../plugins';
import { formatBytes, isMobile } from './helper';

// Ajoute un attribut ayant la valeur du champ
export function inputsAndTextareaLabel() {
  const elementsString = 'input[type="text"], input[type="email"], input[type="search"], input[type="tel"], textarea';
  const formElements = document.querySelectorAll(elementsString);
  const formElementsLength = formElements.length;
  let i;

  for (i = 0; i < formElementsLength; i += 1) {
    formElements[i].parentNode.parentNode.dataset[`${formElements[i].tagName.toLowerCase()}value`] = formElements[i].value;
  }

  const setValue = (e) => {
    e.target.parentNode.parentNode.dataset[`${e.target.tagName.toLowerCase()}value`] = e.target.value;
  };

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    events: 'input',
    targets: elementsString,
    function: setValue,
  });

  OBSERVER.on('inputsAndTextareaLabel');
}

// Modification de la hauteur d'un textarea selon son contenu
export function textareaHeight() {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`;
  };

  OBSERVER.add({
    name: 'textareaHeight',
    events: 'input',
    targets: 'textarea',
    function: onInput,
  });

  OBSERVER.on('textareaHeight');
}

// Permet de supprimer le contenu d'un input
export const clearInput = () => {
  const onClick = (e) => {
    const input = e.currentTarget.parentNode.querySelector('.js-input-to-clear');
    input.value = '';
    input.focus();
    input.parentElement.parentElement.dataset.inputvalue = '';
    input.classList.remove('valid');
  };

  OBSERVER.add({
    name: 'clearInput',
    events: 'click',
    targets: '.js-clear-input',
    function: onClick,
  });

  OBSERVER.on('clearInput');
};

// Retourne la dropZone en fonction de son nom
export function getDropzone(name) {
  let selectedDropzone = false;
  // eslint-disable-next-line no-undef
  Dropzone.instances.forEach((dropzone) => {
    if (dropzone.element.id === name) selectedDropzone = dropzone;
  });
  return selectedDropzone;
}

// Reset les dropzones du formulaire
export function resetDropzone(formObj) {
  // recherche des dropzones du form
  const dropZoneList = $('.fileField', $(formObj));

  // reset des dropzones du form
  dropZoneList.each((index, element) => {
    // pour chaque drop zone trouvé dans le form on cherche son instance de Dropzone
    const dropZoneInstance = getDropzone(element.id);
    dropZoneInstance.removeAllFiles();
  });
}

// Permet de changer le label des input files
export function fileUpload() {
  const clear = (name, element, currentLabelText) => {
    const paramEl = element;
    paramEl.value = '';
    paramEl.nextElementSibling.querySelector('.js-file-text').innerText = currentLabelText;
    paramEl.parentNode.dataset.file = '';
    OBSERVER.off(`clear${name}`);
  };

  const changeLabel = (e) => {
    const self = e;
    const label = e.currentTarget.nextElementSibling.querySelector('.js-file-text');
    const currentLabelText = label.innerText;
    let i;
    let newLabel = '';
    const fileLength = e.currentTarget.files.length;

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i = 0; i < fileLength; i += 1) {
          const file = e.currentTarget.files[i];
          const name = e.currentTarget.getAttribute('name');
          newLabel += `${i + 1}. `;

          if ('name' in file) newLabel += `fichier: ${file.name}, `;
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`;

          const onClear = () => clear(name, self.target, currentLabelText);

          OBSERVER.add({
            name: `clear${name}`,
            events: 'click',
            targets: e.currentTarget.previousElementSibling,
            function: onClear,
          });

          OBSERVER.on(`clear${name}`);
        }
        e.currentTarget.parentNode.dataset.file = newLabel;
        label.innerText = newLabel;
      }
    }
  };

  OBSERVER.add({
    name: 'fileUpload',
    events: 'change',
    targets: 'input[type=file]',
    function: changeLabel,
  });

  OBSERVER.on('fileUpload');
}

// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export function tailSelect() {
  const themePath = window.config.theme_path;

  if (!isMobile()) {
    // eslint-disable-next-line no-undef
    tail.select('select', { animate: false });
  }

  // Ajouter les images dans les options du Tail Select
  let i;
  let j;
  const selectBoxes = document.querySelectorAll('.tail-select');
  const selectBoxesLength = selectBoxes.length;

  // Looper dans chaques boites de sélections Tail Select
  for (i = 0; i < selectBoxesLength; i += 1) {
    const tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option');
    const nativeSelect = selectBoxes[i].previousElementSibling;
    const nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)');

    // Ajouter l'icone en symbole
    const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    useElem.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-pointer`,
    );
    svgElem.appendChild(useElem);
    // selectBoxes[i].querySelector('.select-label').appendChild(svgElem)
    selectBoxes[i].appendChild(svgElem);

    for (j = 0; j < nativeSelectItems.length; j += 1) {
      // Looper dans chaques item du Tail Select
      const imgPath = nativeSelectItems[j].dataset.image;
      if (typeof imgPath !== 'undefined' && imgPath !== '') {
        const newImage = document.createElement('img');
        newImage.src = imgPath;
        tailSelectItems[j].classList.add('has-image');
        tailSelectItems[j].appendChild(newImage);
      }
    }
  }
}

// Initialisation du champ de timepicker
export function timepicker() {
  if (!isMobile()) {
    $('.form-control.timepicker').datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
      },
    });
  }
}

// Initialisation du champ de calendrier
export function calendarInput() {
  // Si on est pas en mobile, mettre le calendrier en JS
  if (!isMobile()) {
    $.fn.datepicker.dates.fr = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre',
        'décembre'],
      monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'jui', 'août', 'sep', 'oct', 'nov', 'déc'],
      today: "Aujourd'hui",
      clear: 'Clear',
      format: 'dd/mm/yyyy',
      titleFormat: 'MM yyyy',
      weekStart: 0,
    };

    $('.datepickerWrapper').datepicker({
      language: 'fr',
      format: 'dd MM yyyy',
      todayHighlight: true,
      startDate: $('.datepickerWrapper').data('startDate'),
    });

    $('.input-calendar input').on('focus', ({ currentTarget }) => {
      const element = $(currentTarget).parent().parent().find('.datepickerWrapper');
      element.addClass('show');
    });

    $('.datepickerWrapper').on('changeDate', function changeDate({ currentTarget }) {
      $(currentTarget).prev().find('input').val($(this).datepicker('getFormattedDate'));
      $('.datepickerWrapper').removeClass('show');
    });

    const closeCalendar = () => {
      let i;
      const x = document.querySelectorAll('.datepickerWrapper');

      for (i = 0; i < x.length; i += 1) {
        x[i].classList.remove('show');
      }
    };

    const preventClose = (e) => e.stopPropagation();

    OBSERVER.add({
      name: 'input-calendar',
      events: 'click',
      function: closeCalendar,
    });

    OBSERVER.add({
      name: 'input-calendar',
      events: 'click',
      targets: '.input-calendar',
      function: preventClose,
    });

    OBSERVER.on('input-calendar');

    // Si on est en mobile, mettre utiliser les calendriers en HTML5
  } else {
    $('.input-calendar input').attr('type', 'date');
  }
}

// AUTOCOMPLETE Search
export function fastResult(form) {

  // Initialisation.
  // $('#fastResults').stop(true, false).animate({ height: 'hide' }, { duration: 0 })

  var ajaxRequest = false //Indique si une requete ajax pour l'autocomplete est déjà en cours
  var searchInput = $(form + ' .autocomplete') //Pointeur sur le input de recherche

  $(searchInput).keyup(function(e) {

    if( ajaxRequest ) {
      // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
      ajaxRequest.abort()
    }

    var keycode = (e.keyCode ? e.keyCode : e.which)

    if ( keycode != 13 ) {
      if ( $(this).val().length >= 3 ) {
        ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
          data: { q: $(this).val() },
          update: { 'rechercheAutocomplete::autocomplete':'#fastResults'},
          complete: function (data) {
            // Results loaded.
            ajaxRequest = false
            $('#fastResults').css('height','')
          },
        })
      } else {
        $('#fastResults').css('height','0px')
      }
    }
  })
}

export function showAllResults() {
  $('.js-submit-search').on('click', () => {
    if ($('#mainSearchForm .autocomplete').val().length > 0)
      $('#mainSearchForm').submit()
    else if ($('#mobileSearchForm .autocomplete').val().length > 0)
      $('#mobileSearchForm').submit()
  })
}

// Le package à appliquer pour les formulaires
export function formsPackage() {
  inputsAndTextareaLabel();
  fileUpload();
  tailSelect();
  textareaHeight();
  calendarInput();
  timepicker();
}
