import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';
import Accordions from '../classes/accordions';

export default function overlayShare() {
  const ovShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      },
    },
    options: {
      speed: 800,
    },
  });

  ovShare.init();

  // if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
  //   __sharethis__.init(__sharethis__.config);
  // }

  // Message de confirmation de copie de l'URL ----------------
  /* L'alert bloque le copy, donc il se fait juste quand on clique sur "OK",
    donc si on veux un alert, il faut mettre un ti timeout de 300ms
    ou ben faire notre prope ti popup sans bouton qui disparait après quelques secondes. */
  function confirmClipboardCopy() {
    setTimeout(() => {
      // eslint-disable-next-line no-alert
      alert('L’adresse URL a bien été copiée dans votre presse-papier.');
    }, 300);
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');
}

export function overlaySearch() {
  const search = new Overlay({
    name: 'search',
    events: {
      openComplete: true,
    },
    create: { close: false },
    click: {
      buttons: {
        trigger: '[data-js="trigger-search"]',
        toggle: '[data-js="toggle-search"]',
        close: '[data-js="close-search"]',
        switch: '[data-js="toggle-overlay-menu"]',
      },
    },
    animations: {
      selector: '.overlay-search__container',
      styles: [{
        property: 'height',
        value: '100%',
      }],
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  });

  search.init();

  const html = document.querySelector('html');

  let searchInputLength = 0;

  function changeSection(e) {
    searchInputLength = e.target.value.length;

    if (searchInputLength >= 3 && html.dataset.section !== 'results') {
      search.trigger('results');
    } else if (searchInputLength < 3 && html.dataset.section !== 'suggestions') {
      search.trigger('suggestions');
    }
  }

  function openOnInputFocus() {
    html.dataset.section = '';

    if (!html.dataset.section) {
      if (searchInputLength >= 3) {
        search.trigger('results');
      } else if (searchInputLength < 3) {
        search.trigger('suggestions');
      }
    }
  }

  function onClearInput() {
    searchInputLength = 0;

    if (html.dataset.section !== 'suggestions') {
      search.trigger('suggestions');
    }
  }

  function onOpenMobile() {
    html.dataset.section = '';

    if (!html.dataset.section) {
      if (searchInputLength >= 3) {
        search.trigger('results');
      } else if (searchInputLength < 3) {
        search.trigger('suggestions');
      }
    }
  }

  const onFocus = () => { document.getElementById('inputFocus').focus(); };

  OBSERVER.add({ name: 'overlaySearch', events: 'onOpenCompleteOverlaySearch', function: onFocus });

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'focus',
    function: openOnInputFocus,
    targets: '[data-input="input"]',
  });

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'input',
    function: changeSection,
    targets: '[data-input="input"]',
  });

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'click',
    function: onClearInput,
    targets: '[data-js="clear-input"]',
  });

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'click',
    function: onOpenMobile,
    targets: '[data-js="button-search"]',
  });

  OBSERVER.on('overlaySearch');
}

// Rotation chevron menu header //

export function overlayDropdown() {
  new Overlay({
    name: 'dropdown',
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-dropdown"]',
        close: '[data-js="close-overlay-dropdown"], a[href]',
        switch: '[data-js="button-search"], .header__search-field .search-field__input',
      },
    },
    options: {
      speed: 1000,
      closeOnResize: false,
    },
    events: {
      close: true,
      trigger: true,
    },
  }).init();

  function showSubMenu(e) {
    const button = e.target;
    const buttonParent = button.parentNode;
    const arrow = buttonParent.querySelector('.overlay-dropdown__svg-arrow');
    const subMenu = buttonParent.querySelector('[data-js="dropdown-sub-menu"]');
    const subMenuAlreadyOpen = document.querySelector('.--showSubMenu');
    let visibleArrow;

    if (subMenuAlreadyOpen) {
      const buttonActive = document.querySelector('.activeButton');
      visibleArrow = subMenuAlreadyOpen.previousElementSibling;

      if (button.classList.contains('activeButton')) {
        visibleArrow.classList.remove('showArrow');
        subMenuAlreadyOpen.classList.remove('--showSubMenu');
        button.classList.remove('activeButton');
      } else {
        visibleArrow.classList.remove('showArrow');
        subMenuAlreadyOpen.classList.remove('--showSubMenu');
        arrow.classList.add('showArrow');
        subMenu.classList.add('--showSubMenu');
        button.classList.add('activeButton');
        buttonActive.classList.remove('activeButton');
      }
    } else {
      button.classList.add('activeButton');
      arrow.classList.add('showArrow');
      subMenu.classList.add('--showSubMenu');
    }
  }

  function hideSubMenu() {
    const subMenuAlreadyOpen = document.querySelector('.--showSubMenu');
    let visibleArrow;

    if (subMenuAlreadyOpen) {
      visibleArrow = subMenuAlreadyOpen.previousElementSibling;
      visibleArrow.classList.remove('showArrow');
      subMenuAlreadyOpen.classList.remove('--showSubMenu');
    }
  }

  OBSERVER.add({
    name: 'overlayDropdownSubMenu',
    events: 'click',
    function: showSubMenu,
    targets: '[data-js="dropdown-parent-button"]',
  });

  OBSERVER.add({
    name: 'overlayDropdownSubMenu',
    events: 'onTriggerOverlayDropdown onCloseOverlayDropdown',
    function: hideSubMenu,
  });

  OBSERVER.on('overlayDropdownSubMenu');

  function specialHover() {
    let hoverElement;
    let allElements;
    let i;
    let element;

    const onHoverIn = (e) => {
      hoverElement = e.target;
      allElements = document.querySelectorAll('.js-hover');

      for (i = 0; i < allElements.length; i += 1) {
        element = allElements[i];
        element.classList.add('no-hover-element');
      }

      hoverElement.classList.remove('no-hover-element');
    };

    const onHoverOut = (e) => {
      hoverElement = e.target;
      allElements = document.querySelectorAll('.js-hover');

      for (i = 0; i < allElements.length; i += 1) {
        element = allElements[i];
        element.classList.remove('no-hover-element');
      }
    };

    OBSERVER.add({
      name: 'hover',
      events: 'mouseenter',
      targets: '.js-hover',
      function: onHoverIn,
    });
    OBSERVER.add({
      name: 'hover',
      events: 'mouseleave',
      targets: '.js-hover',
      function: onHoverOut,
    });

    OBSERVER.on('hover');
  }

  specialHover();
}

export function overlayPopup() {
  new Overlay({
    name: 'popup',
    click: {
      buttons: {
        close: '.js-close-overlay-popup',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
    timeout: {
      delay: 300,
    },
  }).init();
}

export const overlayMenu = () => {
  new Overlay({
    name: 'menu',
    events: {
      close: true,
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-menu"]',
        close: '[data-js="close-overlay-menu"], a[href]',
        switch: '.js-share, [data-js="toggle-search"]',
      },
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: false,
    },
  }).init();

  const mainLevel = new Accordions({
    name: 'MainLevel',
    containerClass: 'js-accordions-main-level',
    accordionClass: 'js-accordion-main-level',
    toggleButtonClass: 'js-accordion-toggle-main-level',
    contentClass: 'js-accordion-content-main-level',
    openedClass: 'js-accordion-opened-main-level',
  });

  mainLevel.init();

  const secondLevel = new Accordions({
    name: 'SecondLevel',
    containerClass: 'js-accordions-second-level',
    accordionClass: 'js-accordion-second-level',
    toggleButtonClass: 'js-accordion-toggle-second-level',
    contentClass: 'js-accordion-content-second-level',
    openedClass: 'js-accordion-opened-second-level',
  });

  secondLevel.init();

  const onClose = () => {
    mainLevel.closeAll();
    secondLevel.closeAll();
  };

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onCloseOverlayMenu',
    function: onClose,
  });

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onCloseAccordionsMainLevel',
    function: onClose,
  });

  OBSERVER.on('overlayMenu');
};

export const overlayCalendar = () => {
  new Overlay({
    name: 'calendar',
    events: {
      close: true,
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-calendar"]',
        close: '[data-js="close-calendar"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  }).init();
};

export const overlayFastLinks = () => {
  new Overlay({
    name: 'fast-links',
    events: {
      close: true,
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-fast-links"]',
        close: '[data-js="close-overlay-fast-links"], a[href]',
      },
    },
  }).init();
};
