import anime from 'animejs';
import { getElementOffset } from './helper';
import { OBSERVER } from '../plugins';

// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (object = {}) => {
  const options = {};
  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const wd = window.document;
  const scrollbar = wd.scrollingElement || wd.body || wd.documentElement;
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo;

  const animation = anime
    .timeline({
      targets: scrollbar,
      duration: options.duration,
      easing: options.easing,
    })
    .add({ scrollTop: getElementOffset({ element }).top + options.offset });

  return animation.finished;
};

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (object) => {
  const options = {};

  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  });

  OBSERVER.add({
    name: 'scrollToBlock',
    events: 'click',
    targets: object.selector,
    function: onClick,
  });

  OBSERVER.on('scrollToBlock');
};
